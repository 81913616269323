import { render, staticRenderFns } from "./Schulungen.vue?vue&type=template&id=5a1a3dba&scoped=true&"
import script from "./Schulungen.vue?vue&type=script&lang=js&"
export * from "./Schulungen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a1a3dba",
  null
  
)

export default component.exports