<template>
  <v-container style="line-height: 1.75; max-width: 1200px">
    <v-row>
      <v-col>
        <div class="uncode_text_column">
          <h1>Kontakt</h1>
          <br>
          <p>
            Simone Waesenberg<br />
            Telefon: +49 176 34545620<br />
            E-Mail:
            <a
              href="mailto:kontakt@therapie-leben-leben.de"
              target="_blank"
              rel="noopener noreferrer"
              >kontakt@therapie-leben-leben.de</a
            >
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
