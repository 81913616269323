<template>
  <div class="mt-15">
    <v-container>
      <center>
        <h1>Die angefragte Seite ist leider nicht Verfügbar!</h1>
        <p>Zurück zur <router-link to="/">Startseite</router-link></p>
      </center>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
