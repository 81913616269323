<template>
  <div class="pb-15 line-height-1-5">
    <v-container style="max-width: 1200px">
      <v-row>
        <v-col cols="12" md="6">
          <h1>Leben leben … in sicherer Bindung</h1>
          <br>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            „Bindung ist das Fundament der Persönlichkeit.“ <br />
            <b>Prof. Dr. Karl Heinz Brisch</b>
          </p>
          <br>
          <p>
            Das Thema Bindung beschäftigte mich schon lange. <br />
            Als mir klar wurde, welch großen Einfluss Bindung auf die
            Entwicklung der Persönlichkeit hat, wurde es zu meiner persönlichen
            Herzensangelegenheit.
          </p>
          <p>
            Mit behutsamer Fachlichkeit und wertschätzender Klarheit stehe ich
            Eltern und Fachpersonal für individuelle Schulungen, Vorträge oder
            therapeutische Begleitung zur Seite.
          </p>
          <p>
            Mein Name ist Simone Waesenberg und ich freue mich darauf, Sie
            kennenzulernen.
          </p>
        <br>
          <h2>Bindungs-Vorträge und -Schulungen</h2>
          <p>
            Maßgeschneiderte Bindungsvorträge und -schulungen (in Präsenz oder
            per Zoom) für Sie und Ihr Team.
          </p>
          <p>Gemeinsam besprechen wir:
            <ul>
                <li>Ihre Ziele, Wünsche und Fragen</li>
                <li>die fachliche Zielgruppe (U3, Kindergartenkinder, Schulkinder, Jugendliche, Eltern …)</li>
                <li>die Art der Schulung, sei es in Form eines Vortrages oder einer aktiven Fortbildung</li>
                <li>den Ort (deutschlandweit möglich)</li>
                <li>den zeitlichen Umfang</li>
                <li>den Termin</li>
                <li>die Form der Präsentation, in Präsenz oder per Zoom.</li>
            </ul>
            <b>Mehr zum <router-link to="/vortraege-schulungen">-> Schulungsangebot</router-link></b> <br>
            <b>Nehmen Sie gerne <router-link to="/kontakt">Kontakt</router-link> mit mir auf.</b>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
          max-width="560"
            src="./assets/heilpraktikerin-waesenberg-simone-dortmund-landkreis.jpg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
