<template>
  <v-container style="line-height: 1.75; max-width: 1200px">
    <v-row>
      <v-col>
        <h1>Impressum</h1>
        <br>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          Leben leben … in sicherer Bindung<br />
          Simone Waesenberg<br />
          Waldstr. 26<br />
          58239 Schwerte
        </p>
        <p><strong>Kontakt</strong> <br>
          Telefon: +49 176 34545620<br />
          E-Mail: kontakt@therapie-leben-leben.de
        </p>
        <h2>
          Verantwortliche für den Inhalt i.S.d. § 18 Abs. 2 MStV
        </h2>
        <p>
          Leben leben … in sicherer Bindung<br />
          Simone Waesenberg<br />
          Waldstr. 26<br />
          58239 Schwerte
        </p>
        <p>
          (sowie verantwortlich für den&nbsp;<a
            href="https://therapie-leben-leben.de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
            >Datenschutz</a
          >)
        </p>
        <p>
          Impressum gilt für Profil in
          <a
            href="https://www.linkedin.com/in/SimonePausch"
            target="_blank"
            rel="noopener noreferrer"
            >LinkedIn</a
          >
        </p>
        <h2>
          Bildnachweis
        </h2>
        <p>
          <a
            href="http://www.sabrinazeuge.de/"
            target="_blank"
            rel="noopener noreferrer"
            >Fotografin Sabrina Zeuge</a
          >
        </p>
        <h2>Webdesign und technische Erstellung</h2>
        <p>
          <a
            href="https://www.kerstin-paar.de"
            target="_blank"
            rel="noopener noreferrer"
            >Kerstin Paar – Webdesign | Blogmarketing |
            Social-Media-Marketing</a
          >
        </p>
        <h2>Aufsichtsbehörde
        </h2>
        <p>
          Die Erlaubnis zur Ausübung der Heilkunde ohne Bestallung
          (Heilpraktikergesetz)<br />
          – eingeschränkt auf das Gebiet der Psychotherapie – wurde am
          15.04.2014 ausgestellt durch:<br />
          Gesundheitsamt Stadt Dortmund<br />
          Hövelstr. 8<br />
          44137 Dortmund
        </p>
        <p>
          Die Berufsbezeichnung Staatlich anerkannte Heilpädagogin bestätigte
          das Anna-Zillken-Berufskolleg am 27.01.2012,<br />
          Anna-Zillken-Berufskolleg<br />
          Arndtstr. 5<br />
          44135 Dortmund
        </p>
        <h2>Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:<br />
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&amp;lng=DE"
            target="_blank"
            rel="noopener noreferrer"
            >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&amp;lng=DE</a
          >
        </p>
        <p>
          Unsere E-Mail-Adresse finden Sie oben im Impressum.&nbsp;Wir sind
          nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
          einer&nbsp;Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2>Haftung für Inhalte</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den&nbsp;allgemeinen Gesetzen verantwortlich.
          Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
          nicht&nbsp;verpflichtet, übermittelte oder gespeicherte fremde
          Informationen zu überwachen oder nach Umständen&nbsp;zu forschen, die
          auf eine rechtswidrige Tätigkeit hinweisen.<br />
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
          der&nbsp;Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden&nbsp;Rechtsverletzungen werden wir
          diese Inhalte umgehend entfernen.
        </p>
        <h2>Haftung für Links</h2>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben.&nbsp;Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
          der&nbsp;verlinkten Seiten ist stets der jeweilige Anbieter oder
          Betreiber der Seiten verantwortlich. Die verlinkten&nbsp;Seiten wurden
          zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
          Rechtswidrige&nbsp;Inhalte waren zum Zeitpunkt der Verlinkung nicht
          erkennbar.<br />
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer&nbsp;Rechtsverletzung nicht
          zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
          derartige Links&nbsp;umgehend entfernen.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen&nbsp;Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der&nbsp;Grenzen des Urheberrechtes bedürfen der
          schriftlichen Zustimmung des jeweiligen Autors bzw.&nbsp;Erstellers.
          Downloads und Kopien dieser Seite sind nur für den privaten, nicht
          kommerziellen Gebrauch&nbsp;gestattet.&nbsp;Soweit die Inhalte auf
          dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter&nbsp;beachtet. Insbesondere werden Inhalte
          Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine&nbsp;Urheberrechtsverletzung aufmerksam werden, bitten wir um
          einen entsprechenden Hinweis. Bei&nbsp;Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p>(Quelle: eRecht24)</p>
        <br><br>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
