<template>
  <v-container class="pb-15" style="max-width: 1200px">
    <v-row>
      <v-col>
        <h1>Schulungen – in Präsenz oder per Zoom</h1>
        <br />
        <p>
          <u>2023</u>
          <br />
          <br />
          Bindungstheorie, Bindungsentwicklung und Bindungsstile –
          Bindungsorientierung in der Heilpädagogischen Diagnostik <br />
          <i>Europäische Akademie für Heilpädagogik</i>

          <br /><br /><br />

          Sicherheit in der heilpädagogischen Arbeit: <br />
          Kinder und Eltern mit problematischen Bindungsstilen oder einer
          Bindungsstörung <br />
          <i>Europäische Akademie für Heilpädagogik</i>

          <br /><br /><br />

          „Sicher(nd)e Begleitung der Bindungsentwicklung“ <br />
          <i>Europäische Akademie für Heilpädagogik</i>

          <br /><br /><br />

          „In kritischen Situationen als sichere Bindungsperson handeln“ <br />
          Bindungsstile und Bindungsstörungen identifizieren, differenzieren und
          in der täglichen praktischen Arbeit anwenden <br />
          <i>Evangelische Stiftung Loher Nocken</i>
        </p>
        <br /><br /><br />
        <p>
          <u>2022</u><br /><br />

          Sicher(nd)e Bindungpersonen in kritischen Zeiten<br />
          3-teilige Bildungsreihe<br />
          <i>Drogenberatungsstelle Wuppertal</i>

          <br /><br /><br />

          „Feinfühlige Klarheit im sprachlichen Austausch mit Kindern und
          Eltern“<br />
          <i>KiTa Klapperksite, Berlin</i>

          <br /><br /><br />

          „Feinfühlige Bindungsbegleitung von Mutter und Säugling“<br />
          Fortbildung für Fachpersonal im Bereich Mutter-Kind-Einrichtung<br />
          <i>Ev. Stiftung Loher Nocken</i>

          <br /><br /><br />

          „Sicher(nd)e Begleitung der Bindungsentwicklung“<br />
          <i>Europäische Akademie für Heilpädagogik</i>

          <br /><br /><br />

          „Bindungs-Sicherer Umgang in der Paarbeziehung – Mit einem chronisch
          kranken Kind“<br />
          Fachliche und therapeutische Begleitung durch das Jahrestreffen
          2021<br />
          <i>epilepsie bundes-elternverband e.V., Winterberg</i>
        </p>
<br /><br />
        <h1>Referenzen</h1>
        <br>
        <p>
          <u>2021</u> <br> <br>

          Ausbildung von künftigen Schulbegleiter*innen<br />
          Bindungstheorie – Bindungsentwicklung – Bindungsdynamik<br />
          Feinfühlige Arbeit in der täglichen Praxis<br />
          <i>AWO Ennepe-Ruhr</i>

          <br /><br /><br />

          Schulungsreihe für Eltern zum Aufbau einer tragenden Bindungsbeziehung
          mit Ihrem Baby und Kleinkind<br />
          <i>Bauch- und Babyzwerg, Lupfmi Ravensburg</i>

          <br /><br /><br />

          „Bindungs-Sicher(nd) arbeiten von Anfang an“<br />
          Vortrag<br />
          <i>Netzwerk für Ennepetaler Familien „Gemeinsam im Blick“, Ennepetal</i>

          <br /><br /><br />

          Sicher(nd)e Begleitung der Bindungsentwicklung von der Schwangerschaft
          bis zum Kleinkind:<br />
          Ein Blick aus heilpädagogischer Perspektive<br />
          <i>Europäische Akademie für Heilpädagogik im BHP e.V.</i>

          <br /><br /><br />

          „Bindungs-Sicher(nd) arbeiten mit Schwangeren, Säuglingen und
          Kleinkindern“<br />
          <i>profamilia Beratungsstelle, Witten</i>

          <br /><br /><br />

          „Bindungssicher(nd) handeln als Kindertagespflegepersonen“<br />
         <i> VHS EN-Süd</i>

          <br /><br /><br />

          „Weiterbildungsreihe: Bindungstheorie, Bindungsentwicklung und
          Bindungsstile –<br />
          Bindungsorientierung in der Heilpädagogischen Diagnostik“<br />
          <i>Europäische Akademie für Heilpädagogik</i>
        </p>
        <br><br>
        <p>
          <u>2020</u><br /><br />

          Elternschulung 2020<br />
          „Sichere Eltern-Kind-Bindung erfolgreich gestalten“ Eltern-Schulung<br />
          <i>Krabbelgruppe Lupfmi, Ravensburg</i>

          <br /><br /><br />

          „Bindungs-Sicher(nd) arbeiten in der stationären Kinder- und
          Jugendhilfe“<br />
          Gruppen – und Fallarbeit, Reflektion<br />
          <i>Kinder- und Jugendhilfe GutenGrund, Unna</i>

          <br /><br /><br />

          „Bindungs-Sicher(nd) arbeiten als KiTa-Team“<br />
          Tagesschulung: Gruppen – und Fallarbeit, Reflektion<br />
         <i> KiTa Klapperkiste, Berlin</i>

          
        </p>
        <br /><br /><br />
        <p>
          <u>2019</u><br /><br />

          „Bindungsarbeit mit verhaltensauffälligen Jugendlichen“<br />
          Vortrag und Workshop<br />
         <i> Pro Kid – Hilfen für Kinder mit Beeinträchtigungen e.V. – Witten</i>

          <br /><br /><br />

          „Bindungssicher in der pädagogischen Arbeit mit Kindern“<br />
          Vortrag<br />
         <i> Zusammen stark – AWO Projekt – Witten</i>

          <br /><br /><br />

          „Bindungssicher(nd) in der pädagogischen Arbeit“<br />
          Zwei aufeinander aufbauende Schulungen<br />
         <i> Aqa Familienpflegedienst – Gevelsberg / Witten</i>

          <br /><br /><br />

            <ol>
                <li>„Bindungsfundament in der pädagogischen Arbeit“</li>
                <li>„Bindungstypen – Macht und Schutz in rechtsextremen /
          nationalistischen Gruppierungen“</li>
                <li>„Bindungssicher arbeiten – in kritischen Situationen, im Kurzkontakt
          und in langfristiger Zusammenarbeit“</li>
            </ol> <br>
          Vortragsreihe mit Gruppenarbeit und Austausch<br />
          Jahrestagung Streetwork/Mobile Jugendarbeit<br />
         <i> LWL-Bildungszentrum Jugendhof Vlotho</i>

          <br /><br /><br /><br />

          „Bindungssicher in der pädagogischen Arbeit“<br />
          Ausbildung von SchulbegleiterInnen 2 x jährlich, jeweils 3 Tage<br />
          Gruppen – und Fallarbeit, Reflektion<br />
         <i> AWO EN – Gevelsberg / Witten</i>

          <br /><br /><br />

          „Bindung und das Leben“<br />
          Kurz-Spotlight<br />
          <i>Frauen-Netzwerk der Dortmunder Klünglerinnen</i>

          
        </p>
        <br /><br />
        <p>
          <u>2018</u><br /><br />

          „Umgang mit schwierigen Situationen im Kontext zu
          Bindungsdynamiken“<br />
          Vortrag<br />
          <i>Netzwerkkonferenz – Jugend stärken im Quartier, Ennepetal</i>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
