<template>
  <v-container class="pb-15 p-spacing" style="line-height: 1.5; max-width: 1200px">
    <v-row>
      <v-col cols="12" md="8">
        <h1 style="line-height: 1">Simone Waesenberg</h1>
        <br />
        <p>
          <strong>Aktuell Universitätslehrgang Early Life Care<br /> </strong>
          Akademische Expertin – Abschluss 06/2022<br />
          ELC Master of Science – geplanter Abschluss 06/2023
        </p>
        <p>
          Beratung, Begleitung, Diagnostik, Therapie und Versorgung am
          Lebensbeginn<br />
          Schaffung optimaler Bedingungen für Kinder und Eltern bzw. Familien
          rund um Kinderwunsch, Schwangerschaft, Geburt und frühe Kindheit mit
          besonderem Blick auf Risikosituationen<br />
          Paracelsus – Medizinische Privatuniversität, Salzburg
        </p>
        <br />
        <h2>Berufsausbildungen</h2>
        <br />
        <p>
          <strong>Psychotherapeutische Heilpraktikerin</strong><br />
          Gesundheitsamt Dortmund
        </p>
        <p>
          <strong>Staatlich anerkannte Heilpädagogin</strong><br />
          Thema der Abschlussarbeit:<br />
          Heilpädagogische Gruppenarbeit unter Einbezug von Bindungsdynamiken<br />
          AZBK, Dortmund
        </p>
      </v-col>
      <v-col cols="12" md="4">
        <v-img
          max-width="450"
          src="./assets/heilpraktikerin-waesenberg-simone-dortmund-landkreis.jpg"
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2>Beruflich Aktuell</h2>
        <p>
          seit Januar 2022 als selbständige Trauma- und Bindungstherapeutin bei
          <br />
          <a
            href="https://www.drobs-wtal.de/index.php?id=78&amp;L=0"
            target="_blank"
            rel="noopener"
          >
            <span style="text-decoration: underline">
              Beratungsstelle für Drogenberatung e. V. in Wuppertal
            </span>
          </a>
          <br />
          Projektarbeit<br />
          Selbständige Therapeutin<br />
          Referentin
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Weiterbildungen</h2>
        <p>
          <strong>Bindungspsychotherapie</strong> – Bindungsbasierte Beratung
          und Therapie – Modul 3<br />
          Anwendung einer bindungsbasierten Beratung und Therapie für
          Schulkinder<br />
          und Jugendliche<br />
          Prof. Dr. med. Brisch, Ulm
        </p>
        <p>
          <strong>SAFE<sup>®</sup> Mentorin</strong><br />
          Begleitung von Eltern zur Förderung einer sicheren
          Eltern-Kind-Bindung, beginnend mit der 24. Schwangerschaftswoche bis
          zum einschließlich 1. Lebensjahr des Kindes<br />
          Prof. Dr. med. Brisch, Ulm
        </p>
        <p>
          <strong>SATe Practitionerin</strong> (Somatic Attachment Training
          experience)<br />
          Heilsame Arbeit mit Bindungs- dynamiken und –verletzungen, 2 Jahre<br />
          Dr. D. Poole Heller und E. Schneider-Kaiser, UTA Akademie, Köln
        </p>
        <p>
          <strong>SE Practitionerin</strong> (*Somatic Experiencing)<br />
          Schock- und Entwicklungstrauma, 3 Jahre<br />
          nach Peter Levine, UTA Akademie, Köln
        </p>
        <p>
          <strong>Körperpsychotherapeutin Reskue<sup>®</sup></strong>
          (Weiterbildung und Assistenz)<br />
          Methode setzt sich zusammen aus Elementen der Gestalt-, Trauma- und<br />
          Körpertherapie, sowie Innere Kind Arbeit, 5 Jahre<br />
          Bernward Bog, Institut für humanistische Psychotherapie,
          Renningen-Malmsheim
        </p>
        <p>
          <strong>Sukzessive Leitungsübernahme</strong> der therapeutischen
          Jahresgruppe
        </p>
        <p>
          <strong>Gestalttherapeutin</strong><br />
          Ressourcen und potentialorientierte Arbeitsausrichtung<br />
          nach Fritz und Laura Pearls, 4&nbsp; Jahre, Symbolon-Institut,
          Nürnberg
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Seminare</h2>
        <p>
          <strong>SAFE<sup>® </sup>– Spezial</strong><br />
          Thema: “Krippe“, sowie „Bindungspsychotherapie mit Schwangeren“<br />
          Prof. Dr. med. Brisch, München
        </p>
        <p>
          <strong>Kinder vor seelischen Verletzungen schützen</strong><br />
          Trauma-Arbeit mit Kindern<br />
          *SE mit Maggie Kline, UTA Akademie, Köln
        </p>
        <p>
          <strong>Bindung unter schwierigen Bedingungen</strong><br />
          Klinikum Dortmund
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Beruflicher Werdegang</h2>
        <p>
          <strong>Projektarbeit</strong><br />
          Arbeit mit schuldistanzierten / delinquenten Kindern und
          Jugendlichen<br />
          Case Management<br />
          Zusammenarbeit mit Jugendämtern und Kooperationspartnern<br />
          Elternarbeit<br />
          Hilfeplangespräche<br />
          Therapeutische Bindungsarbeit mit Schulkindern und Jugendlichen
        </p>
        <p>
          <strong>Leitung einer intensivpädagogischen Wohngruppe</strong> mit
          hoch traumatisierten, bindungsverletzten Kindern (Alter 6-15 Jahre)<br />
          Schwerpunkte:<br />
          Schaffen eines sicheren Lebens- und Bindungsumfeldes<br />
          Erstellung der Förderpläne – Hilfeplangespräche<br />
          Elternarbeit<br />
          Teamführung
        </p>
        <p>
          <strong>Kreativtherapeutin in einer psychosomatischen Klinik</strong
          ><br />
          Schwerpunkte:<br />
          Einzel- und Gruppentherapie mit traumatisierten, bindungsverletzten
          Jugendlichen<br />
          Einzel- und Gruppentherapie mit erwachsenen und jugendlichen Patienten
          mit Essstörungen
        </p>
        <p>
          <strong>Projektarbeit:</strong><br />
          Tagesstruktur im Wohnhaus für junge Menschen mit Kanner- und Asperger
          Autismus
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Verbandsmitgliedschaft</h2>
        <p>
          Verband freier Psychotherapeuten –
          <a
            href="https://www.vfp.de/"
            target="_blank"
            rel="noopener noreferrer"
            >www.vfp.de</a
          >
          <br>
          bhp Berufs- und Fachverband für Heilpädagogik E.V. –
          <a
            href="http://bhponline.de/"
            target="_blank"
            rel="noopener noreferrer"
            >www.bhponline.de</a
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.p-spacing p {
  padding-bottom: 10px;
}
</style>
