import Vue from "vue";
import Router from "vue-router";
import Landing from "@/Landing";
import Schulungen from "@/Schulungen";
import Therapie from "@/Therapie";
import Vita from "@/Vita";
import Impressum from "@/Impressum";
import Datenschutz from "@/Datenschutz";
import Kontakt from "@/Kontakt";
import ErrorPage from "@/ErrorPage";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      component: Landing,
      meta: {
        title: 'Leben leben... in sicherer Bindung'
      }
    },
    {
      path: "/vortraege-schulungen",
      name: "vortraege-schulungen",
      component: Schulungen,
      
    },
    {
      path: "/therapie",
      name: "therapie",
      component: Therapie,
    },
    {
      path: "/simone-waesenberg",
      name: "vita",
      component: Vita,
    },
    {
      path: "/impressum",
      name: "impressum",
      component: Impressum,
    },
    {
      path: "/datenschutz",
      name: "datenschutz",
      component: Datenschutz,
    },
    {
      path: "/kontakt",
      name: "kontakt",
      component: Kontakt,
    },
    {
      path: "*",
      name: "404-not-found",
      component: ErrorPage,
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

const DEFAULT_TITLE = 'Leben leben... in sicherer Bindung';

// eslint-disable-next-line
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
