<template>
  <v-app>
    <!-- <div>
      <v-app-bar color="white" app height="110">
        <div
          :class="
            $vuetify.breakpoint.md
              ? 'd-flex align-center mr-4'
              : 'd-flex align-center mr-15'
          "
        >
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/Leben-leben-sicherer-Bindung.jpg"
            transition="scale-transition"
            width="250"
          />
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp">
          <router-link to="/" class="header-link">
            Leben leben - in sicherer Bindung
          </router-link>
          <router-link to="/vortraege-schulungen" class="header-link"
            >Schulungen</router-link
          >
          <router-link to="/therapie" class="header-link">Therapie</router-link>
          <router-link to="/simone-waesenberg" class="header-link"
            >Vita</router-link
          >
        </div>

        <v-spacer></v-spacer>

        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            class="mr-5"
            href="https://www.linkedin.com/in/SimonePausch"
            target="_blank"
            icon
            color="primary"
          >
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn class="mr-5" icon color="gray" @click="drawer = true">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </v-app-bar>
    </div> -->
    <!-- <div v-if="$vuetify.breakpoint.smAndDown">
      <v-navigation-drawer v-model="drawer" fixed temporary>
        <v-list-item>
          <v-list-item-content>
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="@/assets/Leben-leben-sicherer-Bindung.jpg"
              transition="scale-transition"
              width="250"
            />
          </v-list-item-content>
        </v-list-item>
        <center>
          <v-divider width="90%"></v-divider>
        </center>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <router-link to="/" class="header-link"
                >Leben leben - <br />in sicherer Bindung</router-link
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><router-link to="/vortraege-schulungen" class="header-link"
                  >Schulungen</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><router-link to="/therapie" class="header-link"
                  >Therapie</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><router-link to="/simone-waesenberg" class="header-link"
                  >Vita</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div> -->
    <!-- background-color: #cdcdcd; -->
    <v-main style="background-color: white; margin-top: 50px">
      <!-- <router-view :key="$route.name + ($route.params.id || '')"></router-view> -->
      <v-card
        style="
          background-color: #eaedf9;
          width: 100%;
          height: 100%;
          align-content: center;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="background-color: white; border-radius: 40px; padding: 30px"
        >
          <h1 style="font-size: 3rem">Wartungs<wbr />modus</h1>
          <hr style="margin: 30px 0px" />

          <p style="font-size: 1.25rem">
            Auf der Webseite finden derzeit Wartungsarbeiten statt, wodurch die
            Webseite nicht erreichbar ist. <br />
            Bitte kontaktieren Sie mich per E-Mail oder Telefon: <br />
            <br />
          </p>
          <!-- align content next to each other -->
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <v-img
              max-width="300"
              src="./assets/heilpraktikerin-waesenberg-simone-dortmund-landkreis.jpg"
            >
            </v-img>
            <div style="padding-left: 50px">
              <b>
                Simone Waesenberg <br />
                Mobil: +49 176 34545620 <br />
                E-Mail: kontakt@therapie-leben-leben.de <br />
              </b>
            </div>
          </div>
        </div>
      </v-card>
    </v-main>

    <v-footer padless>
      <v-card flat tile class="flex">
        <!-- <v-card-text class="pt-12 pl-10" style="background-color: #eaedf9">
          <h3>Kontakt</h3>
          <br />
          <p class="f-nunito" style="font-size: 12pt; line-height: 1.5">
            Simone Waesenberg <br />
            Mobil: +49 176 34545620 <br />
            E-Mail: kontakt@therapie-leben-leben.de
          </p>
          <p>
            <a href="https://www.linkedin.com/in/SimonePausch"> LinkedIn</a>
          </p>
        </v-card-text> -->
        <v-card-title
          class="pl-10"
          style="height: 100px; color: white; background-color: #040566"
        >
          <span class="f-nunito footer-link">
            <router-link to="/impressum">Impressum</router-link> |
            <router-link to="/datenschutz">Datenschutz</router-link> |
            <router-link to="/kontakt">Kontakt</router-link>
          </span>
          <v-spacer></v-spacer>
          <span class="f-nunito footer-link">
            Copyright 2022 Simone Waesenberg
          </span>
        </v-card-title>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    drawer: false,
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nunito Sans !important;
}

.f-nunito {
  font-family: Nunito Sans !important;
}

body,
a,
p,
span {
  font-family: sans-serif;
}

h1 {
  font-size: 28px;
  font-weight: 600;
  color: #040566;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 6px;
}

h3 {
  font-size: 22px;
  font-weight: 600;
}

a {
  text-decoration: none;
}

.header-link {
  margin-right: 30px;
  color: #040566 !important;
  /* color: black !important; */
  font-weight: 600;
  font-size: 12pt;
}

.footer-link {
  font-weight: 500;
  font-size: 12pt;
}

.footer-link a {
  color: white !important;
}

.line-height-1-5 {
  line-height: 2;
}

.wartung-xs {
}

.wartung {
}
</style>
