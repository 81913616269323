<template>
  <v-container class="pb-15" style="max-width: 1200px">
    <div class="line-height-1-5 mb-15">
      <h1>Trauma- und Bindungsorientierte Einzeltherapie</h1>
      <ul>
        <li>Was bewegt, berührt, beunruhigt Sie?</li>
        <li>
          Welche Themen und Situationen scheinen immer wieder Ihr Leben zu
          erschüttern?
        </li>
        <li>
          Haben Sie häufig den Eindruck, dass es schwierig ist mit anderen
          umzugehen, in Gruppen Ihren Platz zu finden?
        </li>
        <li>
          Fällt es Ihnen schwer, tiefe Freude zu empfinden, statt dessen fühlen
          Sie häufig Unbehagen oder sogar einen tiefen Schmerz, und Sie wissen
          nicht wo diese Gefühle her kommen?
        </li>
      </ul>
      <p>
        In der Einzeltherapie schauen wir gemeinsam auf Ihren persönlichen
        Bindungsstil und darauf, welche emotionalen Folgen die Aktivierung des
        Bindungsstils für Sie mit sich bringen.
      </p>
      <p>
        Ihre Reaktionen besser zu verstehen ermöglicht eine innere Aussöhnung.
      </p>
      <p>
        In Folge können Sie Schritt für Schritt &nbsp;die Selbststeuerung
        übernehmen. Das bedeutet Ihren emotionalen Reaktionen und Aktionen nicht
        mehr hilflos ausgeliefert zu sein sondern sie aktiv und bewusst zu
        gestalten.
      </p>
      <p>
        Begegnungen auf neuen Ebenen werden möglich und steigern die
        Lebensqualität.
      </p>
    </div>
    
    <div>
      <h2>Erstgespräch</h2>
      <p>
        Das Erstgespräch beinhaltet eine ausführliche Anamnese, während der Sie
        von Ihren Themen, Ihrem Umfeld, Ihrer Familie so viel berichten, wie sie
        können und mögen.
      </p>
      <p>
        Gemeinsam schauen wir auf Ihre Ziele und Wünsche, die Sie an eine
        Therapie knüpfen. Wir besprechen die Grenzen und Möglichkeiten unserer
        therapeutischen Zusammenarbeit. Dieses Erstgespräch dauert ca. 60
        Minuten und kostet 50,00 €.
      </p>
      <h2>Honorar</h2>
      <p>Mein Honorar für eine Therapiestunde (60 Minuten) beträgt 95,00 €.</p>
      <h2>Kostenübernahme</h2>
      <p>
        Private- und Zusatzversicherungen erstatten bisweilen die Kosten, zu den
        von Ihnen tariflich vereinbarten Bedingungen. Dies sollte vor
        Therapiebeginn mit der jeweiligen Versicherung abgeklärt werden.
      </p>
      <p>
        Von den gesetzlichen Krankenkassen werden die Leistungen durch
        Psychotherapeutische Heilpraktiker nicht übernommen.
      </p>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
